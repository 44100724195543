/* -------- colors  -------- */
$textColor: #062731;
$headerBlue: #0555FA;
$magenta: #FF1065;
$mainBlue: #40B9E6;
$filterBlue: #d3d5d6;
$filterGray: #94989B;
$borderColor: #CCDFE5;
$filterBgColor: rgba(211, 213, 214, 0.4);
$docsBgColor: #E7F3F7;
$mediumLightGrey: #D0E6ED;
$loginBgColor: #F4FBFD;

$cobaltBlue: #4285f4;
$ppcBlue: #53A7FF;
$ppcOrange: #FF6447;
$ppcPink: #FF5CFF;
$ppcRed: #FF2E00;
$green: #01B747;
$ppcGrey: #d3d5d6;

$white:     #ffffff;
$black:     #000000;
$charcoal:  #727479;
$lightgrey: #eeeeee;


/*            sizing           */
/* =========================== */
$spinner_width: 3em;









// /*  header toolbar  */

// $headerLightGrey: #ECECEC;
// $userInfo: #504e4e;
// $searchButton: #0D84BC;
// $shadowGrey: #CBCBCB;
// $hoverBlue: #0D84BC;
// $headerGrey: #51525e;
// $headerBorder: #9fa0b5;
// $headerGreen: #4ca69a;
// $backgroundColor: #FBFCFD;

// /*  general  */
// $white:     #ffffff;
// $lightwhite:#fafafa;
// $offwhite:  #f5f5f5;
// $lightgrey: #eeeeee;
// $grey:      #cccccc;
// $mediumgrey:#999999;
// $darkgrey:  #666666;
// $lightblack:#222222;
// $offblack:  #070707;
// $black:     #000000;
// $green:     mediumseagreen;
// $brightgreen: #58c127;
// $brightgreenhover: #5db734;
// $red:       #fa2735;
// $charcoal:  #727479;
// $medGrey:   #efefef;
// $disabled:  #a7a9ab;
// $linkblue:  #394F7F;
// $orange:    #ff6347;
// $yellow: #ffd000;
// $defaultBtnGrey: #d6d9e0;
// $backgroundGreen: #eef9e9;
// $buttongrey: #bfbfbf;
// $tableGrey: #dddddd;
// $sidebarGrey: #ebebeb;

// $lightergrey: #fcfcfc;
// $closedPanel: #9e9e9e;
// $semilightgrey: #dddddd;
// $purple: #a537b4;

// /*  company */
// $mainBlue:       #36a9e1;
// $mainDarkerBlue: #1e8fc6;
// $mainGrey:       #383e4b;
// $mainDarkerGrey: #22262e;
// $mainDarkestGrey: darken($mainDarkerGrey, 6.66%);
// $cgRed:          #ff7373;

// /* system colors (for design system moving forward) */
// $base-grey: #a3a3a3;
// $tint-1-grey: #f0f0f0;
// $tint-2-grey: #f5f5f5; /* background-color on body */
// $tint-3-grey: #f9f9f9;
// $base-grey--lighter: #dadada;
// $base-warning: indianred;

// /* user info */
// $userPlaceholderIcon: #ababab;
// $userPlaceholderBackground: #dedede;

// /* tabs */
// $tabBorder: #dee2e6;

// /* HOME */
// $myTasksTopBorder: #04aa9d;
// $dealsAssignedTopBorder: #0a5993;
// $proposalsAssignedTopBorder: #ff8754;

// /* Actions Types */
// $notstarted: #adadad;
// $inprogress: #0a5993;
// $issues: #be1e2d;
// $completed: #04aa9d;
// $late: #be1e2d;

// /* Board */
// $boardColumnBlue: #095992;
// $boardColumnYellow: #fffc5c;
// $boardColumnRed: #ef4b30;
// $boardColumnGreen: #64bb4f;

// /* Dashboard */
// $dashboardNew: #155893;
// $dashboardEstimating: #45a499;
// $dashboardFollowUp: #3dad48;
// $dashboardAwarded: #ed9923;
// $dashboardWon: #ef4b2f;
// $dashboardLost: #be1e2d;

// :export {
//   boardColumnBlue: $boardColumnBlue;
//   boardColumnYellow: $boardColumnYellow;
//   boardColumnRed: $boardColumnRed;
//   boardColumnGreen: $boardColumnGreen;
// }

// $calendarEvent: #c5e9e4;
// $calendarBid: #fde1cd;
// $calendarHardBid: #fa8d3e;
// $calendarSealedBid: #fab380;
// $calendarFollowup: #c1e3fc;
// $calendarFollowupHardBid: #50a8e7;
// $calendarFollowupSealedBid: #79a3c2;
// $calendarTask: #cad4ff;
// $calendarCompletedTask: #83ad46;

// :export {
//   calendarEvent: $calendarEvent;
//   calendarBid: $calendarBid;
//   calendarHardBid: $calendarHardBid;
//   calendarSealedBid: $calendarSealedBid;
//   calendarFollowupHardBid: $calendarFollowupHardBid;
//   calendarFollowupSealedBid: $calendarFollowupSealedBid;
//   calendarFollowup: $calendarFollowup;
//   calendarTask: $calendarTask;
//   calendarCompletedTask: $calendarCompletedTask;
// }

// /* -------- fonts  -------- */
// $default_font: -apple-system,
// BlinkMacSystemFont,
// 'Segoe UI',
// Roboto,
// Helvetica,
// Arial,
// sans-serif,
// 'Apple Color Emoji',
// 'Segoe UI Emoji',
// 'Segoe UI Symbol';

// /* Breakpoints */
// /* =========== */
// $phone:   576px;
// $tablet:  768px;
// $laptop:  992px;
// $desktop: 1200px;
// $bigScreen: 1500px;

// /* DO NOT USE THESE BREAKPOINTS */
// /* ============================ */
// $mq_xsmall:   $phone;
// $mq_small:    $tablet;
// $mq_mobile:    $tablet;
// $mq_medium:   $laptop;
// $mq_large:    $desktop;
// $mq_xlarge:   $desktop;

// /* -------- template sizes  -------- */
// $headerHeight:        40px;
// $footerHeight:        40px;
// $asideWidth:          50px;
// $asideWidthExpanded: 195px;
// $logoHeight:          24px;
// $smallLogoHeight:     26px;
// $pageHeaderHeight:     0px;
// $spinnerWidth:         3em;

// $navItemWidth:        50px;
// $navItemHeight:       45px;
// $mobileFooterHeight:   8em;
// $innerSidebarWidth:  300px;
// $innerContentPadding:  30px 40px;

// /* -------- z-indexes  -------- */
// $templateTopLayer:        21;
// $templateMidLayer:        20;
// $templateBottomLayer:     19;
// $innerContentTopLayer:    11;
// $innerContentMidLayer:    10;
// $innerContentBottomLayer:  9;
// $zIndex__base--top: 102;
// $zIndex__base--high: 101;
// $zIndex__base--mid: 100;
// $zIndex__base--low: 99;
// $zIndex__base--bottom: 98;
// $zIndex__base--below-sidebar: 19;
// $zIndex__floor--base: 0;
// $zIndex__floor--under: -1;


// /*         borders         */
// /* ======================= */
// $border__radius: 0.28571429rem;

// /*            sizing           */
// /* =========================== */
// $spinner_width: 3em;


/*          mixins          */
/* ======================== */

@mixin size($width, $height) {
  width:$width;
  height:$height;
}

@mixin color($color, $background) {
  color:$color;
  background-color:$background;
}

@mixin position($top, $right, $bottom, $left) {
  position:absolute;
  top:$top;
  right:$right;
  bottom:$bottom;
  left:$left;
}

@mixin fixed($top, $right, $bottom, $left) {
  position:fixed;
  top:$top;
  right:$right;
  bottom:$bottom;
  left:$left;
}

@mixin flex($dir, $wrap, $align, $justify) {
  display:flex;
  flex-flow:$dir $wrap;
  align-items:$align;
  justify-content:$justify;
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top:$top;
  padding-right:$right;
  padding-bottom:$bottom;
  padding-left:$left;
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top:$top;
  margin-right:$right;
  margin-bottom:$bottom;
  margin-left:$left;
}

@mixin fadeIn($duration: 150ms, $delay: 0, $timing: ease-in-out) {
  animation-name: fade-in;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-timing-function: $timing;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin expandIn($duration: 150ms, $delay: 0, $timing: ease-in-out, $origin: center) {
  animation-name: expand-in;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-timing-function: $timing;

  @keyframes expand-in {
    from {
      opacity: 0;
      transform: scaleY(0.5);
      transform-origin: $origin;
    }

    to {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: center center;
    }
  }
}

@mixin inlineFlex($dir, $wrap, $align, $justify) {
  display: inline-flex;
  flex-flow:$dir $wrap;
  align-items:$align;
  justify-content:$justify;
}

.input:focus {
  color:rgba(0,0,0,.8);
  box-shadow:none;
}

.dsSlideHeader {
  margin-bottom: .5rem;
}

.closeStatsOverlay {
  width: auto;
  display: inline-block;
  font-weight: 500;
  letter-spacing: .1rem;
  font-family: 'Ubuntu', Arial, sans-serif;
  border-radius: 0;
  border: 1px solid transparent;
  padding: 0 2.6rem;
  cursor: pointer;
  user-select: none;
  text-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
  transition: color 0.3s ease-in-out, border 0.3s ease-in-out, opacity 0.3s ease-in-out, background 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: .6rem;
  padding-right: 1.4rem;
  height: 4.8rem;
  font-size: 1.12rem;
  line-height: 4.8rem;
  text-transform: uppercase;
  color: $filterBlue;
  &:hover {
    color: $white;
    background: $mainBlue;
    border-color: $mainBlue;
    text-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
  }
  svg {
    font-size: 2rem;
    margin-right: 1rem;
    position: relative;
    top: 0.5rem;
  }
}

.box {
  background: #fff;
  padding: 3rem 2.4% 4rem;
  margin-bottom: 4rem;
}
